import PriceBlock from "components/viewProduct/PriceBlock/PriceBlock";
import ViewProductModel from "components/viewProduct/ViewProductModel";
import StonesAdmin from "components/viewProduct/ui/StonesAdmin";
import StringDefaulter from "helpers/StringDefaulter";
import { JewelDto } from "models/Dto/JewelsDto.interface";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { searchFormDataSelector } from "store/product/ProductSelectors";
import { getImageUrlOrDefault } from "utils/image";
import './jewel-duplicata.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createPriceBlocksInfoArray } from "utils/priceBlock.utils";


interface JewelDuplicataProps {
  jewel: JewelDto | undefined;
  loading: boolean;
}

const LoadingDuplicataContent: React.FC = () => {
  return (
    <div className="duplicata">
      <div className="duplicata__loading">
        <CircularProgress />
      </div>
    </div>
  )
}

const JewelDuplicataContent: React.FC<{ jewel: JewelDto | undefined }> = ({ jewel }) => {
  const searchFormData = useSelector(searchFormDataSelector);
  const [isCurrencyEur, setIsCurrencyEur] = useState(true);
  const [currentCarouselImage, setCurrentCarouselImage] = useState(0);

  useEffect(() => {
    setIsCurrencyEur(!searchFormData.currency || searchFormData.currency === 'eur')
  }, [searchFormData]);

  const isMarketplace = () => {
    return jewel?.source_type === "Marketplace";
  };

  const productFactory = new ViewProductModel(jewel);
  const productInfoDataAdminStone = productFactory.mapPostprocessingStone()
  const infoProduct = productFactory.createInfoProductList();
  const carouselData = productFactory.createCarouselData();
  return (
    <div className="duplicata">
      {jewel && (
        <div className="duplicata__carousel mb-20px">
          {carouselData && (
            <div className="duplicata__carousel__thumbnails">
              {carouselData.map((thumb: any, index: number) => (
                <div
                  className="duplicata__carousel__thumbnail"
                  onClick={() => setCurrentCarouselImage(index)}
                  key={index}>
                  <img src={thumb.image} alt="" />
                </div>
              ))}
            </div>
          )}

          <div className="duplicata__carousel__image">
            <img className="duplicata__img" src={getImageUrlOrDefault(carouselData && carouselData[currentCarouselImage].image)} alt="" />
          </div>

        </div>
      )}
      <h3 className="heading-3 mb-20px">{jewel?.lot_title}</h3>

      {createPriceBlocksInfoArray(jewel, isCurrencyEur).map((info, index) => (
        <PriceBlock
          key={index}
          {...info}
        />
      ))}

      <div className="mb-30px">
        <div className="paragraph">
          {StringDefaulter.defaultEmpty(jewel?.source)},{" "}
          {isMarketplace() && jewel?.creator
            ? StringDefaulter.defaultEmpty(jewel?.creator)
            : StringDefaulter.defaultEmpty(jewel?.name)}
        </div>
        {!isMarketplace() && (
          <div className="paragraph">
            {StringDefaulter.defaultEmpty(jewel?.location)},{" "}
            {productFactory.displayedDate}
          </div>
        )}
      </div>

      <div className="duplicata__info">
        {infoProduct?.map((product, index) => {
          if (
            !product?.text && product.label !== "Stones"
          ) {
            return false;
          } else {
            return (
              <div
                className={`duplicata__info__line ${product?.isLong ? "long-content" : ""
                  } ${product?.isUrl ? "is-url" : ""}`}
                key={index}
              >
                <div className="duplicata__info__line__label">
                  <div className="paragraph">{product?.label}</div>
                </div>
                {!product?.isUrl &&
                  product.isLong &&
                  product.label !== "Stones" &&
                  (
                    <div className="duplicata__info__line__content">
                      <div
                        className="paragraph"
                        dangerouslySetInnerHTML={{ __html: product?.text }}
                      ></div>
                    </div>
                  )}
                {!product?.isUrl &&
                  !product.isLong &&
                  product.label !== "Stones" && (
                    <div className="duplicata__info__line__content">
                      <div className="paragraph">{product?.text}</div>
                    </div>
                  )}
                {product?.isUrl && (
                  <div className="duplicata__info__line__content">
                    <a
                      href={product?.text}
                      target="_blank"
                      rel="noreferrer"
                      className="paragraph"
                    >
                      {product?.text}
                    </a>
                  </div>
                )}

                {jewel?.admin
                  && product.label === "Stones"
                  && (
                    <StonesAdmin stonesInfo={productInfoDataAdminStone} />
                  )
                }
              </div>
            );
          }
        })}

        <div className="duplicata__info__line">
          <div className="duplicata__info__line__label"
            style={{ width: 30, flexBasis: 30 }}>
            <div className="paragraph">id</div>
          </div>
          <div className="duplicata__info__line__content">
            <Link className="paragraph" to={`/view-product/${jewel?.result_id}`} target="_blank" >{jewel?.id}</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

const JewelDuplicata: React.FC<JewelDuplicataProps> = ({ jewel, loading }) => {

  if (loading) {
    return <LoadingDuplicataContent />
  } else if (jewel) {
    return <JewelDuplicataContent jewel={jewel} />
  } else {
    return ''
  }

}

export default JewelDuplicata;