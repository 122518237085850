import { Star } from "@material-ui/icons";
import PriceTooltip from "components/shared/price-tooltip/PriceTooltip";
import { formatDate } from "helpers/utils";
import { JewelDto } from "models/Dto/JewelsDto.interface";
import { CurrenciesValues } from "models/Store/Jewels/Currency.interface";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { mainCurrencies } from "utils/currency";
import { getJewelImage } from "utils/image";
import { getStatusLabel } from "utils/jewels.utils";
import { createPriceBlocksInfoArray } from "utils/priceBlock.utils";
import StringDefaulter from '../../../helpers/StringDefaulter';
import { searchFormDataSelector } from "../../../store/product/ProductSelectors";
import './productItems.scss';

export const ProductItem: React.FC<{ product: JewelDto }> = ({ product }) => {
  const searchFormData = useSelector(searchFormDataSelector);

  const [isCurrencyEur, setIsCurrencyEur] = useState<boolean>(true);

  useEffect(() => {
    setIsCurrencyEur(!searchFormData.currency || searchFormData.currency === 'eur')
  }, [searchFormData]);

  const getImage = () => {
    return getJewelImage(product)
  }

  const isMarketplace = () => {
    return product?.source_type === "Marketplace";
  }

  const getCurrencyBase = () => isCurrencyEur ? mainCurrencies[CurrenciesValues.EUR].label : mainCurrencies[CurrenciesValues.USD].label;

  const displayPrice = (label_text: any, price_currency: any, price_currency_secondary: any, price_source: any, alternative_text: any) => {
    if (price_currency || price_source) {
      return (
        <React.Fragment>
          <p className="subtitle">{label_text}</p>
          <div className="paragraph">
            {StringDefaulter.defaultPrice(price_currency, getCurrencyBase(), alternative_text)}
            {price_currency_secondary && (
              ` - ${StringDefaulter.defaultPrice(price_currency, getCurrencyBase(), alternative_text)}`
            )}
            <PriceTooltip tooltipposition="right" /></div>
          <p className="paragraph estimated-price">({StringDefaulter.defaultPrice(price_source, product?.currency, alternative_text)})</p>
        </React.Fragment>);
    } else {
      // No price (not sold / not selling)
      return (<React.Fragment>
        <p className="subtitle">{label_text}</p>
        <p className="paragraph">{StringDefaulter.defaultPrice(product?.price, product?.currency, alternative_text)}</p>
      </React.Fragment>);
    }
  }

  const displayMarketplaceDate = () => {
    if (product?.date_of_sale) {
      return <div className="paragraph">Sold {formatDate(product?.date_of_sale)}</div>;
    } else {
      return <div className="paragraph">First seen {formatDate(product?.created)}</div>;
    }
  }

  const getSoldStatus = (status: any) => {
    if (status?.toLowerCase() === "sold" || status?.toLowerCase() === "closed") {
      return '';
    } else if (status?.toLowerCase() === "not sold") {
      return 'not-sold'
    } else if (status?.toLowerCase() === "for sale") {
      return 'for-sale'
    }
  }


  return (
    <Link
      className="vca-product-item"
      to={`/view-product/${product?.result_id}`}
    >
      <div className="vca-product-item__image">
        <span className={`sold-icon ${getSoldStatus(product?.status)}`}>
          <span className="sold-icon__circle"></span>
          <span className="sold-icon__tooltip">{`${getStatusLabel(product)}`}</span>
        </span>
        {product?.image && (
          <img src={getImage()} alt="illustration" />
        )}
        {!product.image && (
          <span className="sold-icon__no-images">No Image</span>
        )}
      </div>
      <div className="vca-product-item__content">
        <h2 className="heading-3 title">{product?.lot_title}</h2>
        {createPriceBlocksInfoArray(product, isCurrencyEur)
          .map((info, index) => (
            <div className="vca-product-item__content__info" key={index}>
              {displayPrice(info.title, info.valueCurrency, info.valueCurrencySecondary, info.valueSource, "n/a")}
            </div>
          ))}

        <div className="vca-product-item__content__info">
          <div className="paragraph">
            {StringDefaulter.defaultEmpty(product?.source)}, {(isMarketplace() && product?.creator) ? StringDefaulter.defaultEmpty(product?.creator) : StringDefaulter.defaultEmpty(product?.name)}
          </div>
          {!isMarketplace() && <div className="paragraph">{StringDefaulter.defaultEmpty(product?.location)}, {formatDate(product?.date_of_sale)}</div>}
          {isMarketplace() && displayMarketplaceDate()}
        </div>
        {(product?.serial_number) && (
          <div className="vca-product-item__content__info">
            <p className="subtitle">Serial Number</p>
            <p className="paragraph serial-number">
              {product?.serial_number_history && (
                <Star className="star-icon" />
              )}
              <span>{`${product?.serial_number}`}</span></p>
          </div>
        )}

        {(product?.highlight) && (
            <div className="vca-product-item__content__info">
              <p className="subtitle">Matched extracts</p>
              <p className="paragraph highlight">
              <ul>
                {product.highlight.map((item : string, index: number) => (
                    <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
                ))}
              </ul>
              </p>
            </div>
        )}
      </div>
    </Link>
  );
};

ProductItem.propTypes = {
  product: PropTypes.shape({}) as any,
};

export default ProductItem;
